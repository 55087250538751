@import "../../theme.scss";

.event-table {
  border: 1px solid $data-table-header;
}

.event-table thead {
  background-color: $data-table-header;
  font-weight: bold;
}

.event-table tbody tr td {
  color: $data-table-header-color;
  font-family: monospace;
  font-size: 9pt;
  margin: 0;
  padding: 0 1em 0 1em;
}

.event-table-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0;
}

.event-table-loaded {
  @extend .event-table-item;
  width: 9em;
  max-width: 9em;
  text-align: right;
}

.event-table-username {
  @extend .event-table-item;
  width: 20em;
  max-width: 20em;
}

.event-table-filename {
  @extend .event-table-item;
  width: 30em;
  max-width: 30em;
}

.event-table-signature {
  @extend .event-table-item;
}
